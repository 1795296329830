import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface saveReq {
  id?: string
  project_id: number | string
  liable_person: string
  phone?: string // 电话
  sqdd_state?: string
  supply_days?: string
  disburden_person?: string
  prepare_state?: string
  explain?: string
  address?: string
  finish_date?: string
}
export type saveProject = saveReq //导出请求保存的数据结构

// 施工申请 添加接口
export function ConstructionApply(data: saveReq) {
  const url = '/api/project_construction/apply'
  return cateringRequest.post(url, data)
}
