import { cateringRequest, cateringRequestHide } from '@/utils/fetch'

export function apiGetToken(params) {
  const url = '/api/pub/token'
  return cateringRequest.get(url, {
    params
  })
}

//绑定员工
export function apiStaffBind(params) {
  const url = '/api/staff/bind'
  return cateringRequest.post(url, params)
}

//解除绑定
export function apiStaffUnbind(params) {
  const url = '/api/staff/unbind'
  return cateringRequest.get(url, {
    params
  })
}

//根据手机号查找，员工信息。
export function apiGetUserInfoByphone(params) {
  const url = '/api/staff/getUserInfoByphone'
  return cateringRequest.get(url, {
    params
  })
}

//  获取审批流程
interface typeReq {
  type: number
}
export function ApproveConfig(params: typeReq) {
  const url = '/api/pub/approve-config'
  return cateringRequestHide.get(url, {
    params: params
  })
}

//  获取抄送人数据

export function CarboncopyConfig(params: typeReq) {
  const url = '/api/pub/carboncopy-config'
  return cateringRequestHide.get(url, {
    params: params
  })
}
